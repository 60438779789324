import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import Slide1 from "../assets/Images/slider/CELUI/000.webp";
import Slide2 from "../assets/Images/slider/CELUI/001.webp";
import Slide3 from "../assets/Images/slider/CELUI/0001.webp";
import Slide4 from "../assets/Images/slider/CELUI/002.webp";
import Slide5 from "../assets/Images/slider/CELUI/004.webp";
import Slide6 from "../assets/Images/slider/CELUI/005.webp";
import Carousel from 'react-bootstrap/Carousel';

const ImgContainer = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    @media (max-width: 48em) {
      object-position: center 10%;
    }
    @media (max-width: 30em) {
      object-position: center 50%;
    }
  }
`;

const Title = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.text};
  div {
    display: flex;
    flex-direction: row;
  }
  h1 {
    @media (max-width: 30em) {
      font-family: "Butler";
      font-size: calc(4rem);
      color: #FFFEFF;
      text-shadow:0px 0px 5px #fff,
      0px 0px 7px #fff;
    }
  }
  h2 {
    font-size: ${(props) => props.theme.fontlg};
    font-family: "Raleway";
    font-weight: 500;
    text-shadow: 1px 1px 1px ${(props) => props.theme.body};
    margin: 0 auto;
    text-transform: capitalize;
    @media (max-width: 30em) {
      font-size: ${(props) => props.theme.fontmd};
      /* font-size: calc(5rem + 8vw); */
      margin-top: -1.5rem;
    }
  }
`;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 5, // 2
      staggerChildren: 0.3,
    },
  },
};

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const Images = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6];

const CoverVideoMobile = () => {
  return (
    <ImgContainer>
{/*       <DarkOverlay />
 */}
      <Carousel >
        {Images.map((item) => (
          <Carousel.Item key={item}>        
            <img key={item} className="d-block w-100" src={item} alt={item.alt} />
          </Carousel.Item>
          ))}
      </Carousel> 

      <Title variants={container} initial="hidden" animate="show">
      <div>
        
        <motion.h1
            variants={item}
          >
            W
          </motion.h1>
          <motion.h1
            variants={item}
          >
            E
          </motion.h1>
          <motion.h1
            variants={item}
          >
            L
          </motion.h1>
          <motion.h1
            variants={item}
          >
            C
          </motion.h1>
          <motion.h1
            variants={item}
          >
            O
          </motion.h1>
          <motion.h1
            variants={item}
          >
            M
          </motion.h1>
          <motion.h1
            variants={item}
          >
            E
          </motion.h1>
          
        </div>    
      </Title>

    </ImgContainer>
  );
};

export default CoverVideoMobile;