import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavContainer = styled(motion.div)`
  position: absolute;
  left: ${(props) => (props.click ? '0%' : `0%`)};
  top: ${(props) => (props.click ? '0' : `-105%`)};
  transition: all 0.6s ease;
  box-shadow: 2px -12px 30px black;
  /* transform: translateX(-50%); */
  z-index: 6;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  
  @media (max-width: 40em) {
    top: ${(props) => (props.click ? '0' : `-100%`)};
    left: ${(props) => (props.click ? '0%' : `0%`)};
  }

  @media (max-width: 30em) {
    top: ${(props) => (props.click ? '0' : `-107%`)};
    left: ${(props) => (props.click ? '0%' : `0%`)};
  }
`;

const MenuBtn = styled.li`
  background-color: ${(props) => `rgba(${props.theme.textRgba},0.7)`};
  color: ${(props) => props.theme.body};
  outline: none;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  position: absolute;
  top: 110%;
  left: 25%;
  transform: translateX(-50%);
  padding: 20px;

  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 2rem;
  width: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: #333;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
  @media (max-width: 40em) {
    left: 18%;
    top: 103%;
  }
`;

const MenuItems = styled(motion.ul)`
  position: relative;
  height: 100vh;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  flex-direction: column;
  font-family: "Montserrat Thin";
  width: 100%;
    padding: 0px;


  @media (max-width: 40em) {
    flex-direction:column;
    padding:2rem 1rem;
    height: 100vh;
  }
}
`;

const Item = styled(motion.li)`
  text-transform: uppercase;
  color: white;
  /* font-family: Montserrat Medium; */
  text-decoration: none;
  color:white;

  a {
    text-decoration: none;
    color:white;
  }

  @media (max-width: 40em) {
    flex-direction:column;
    padding:0.5rem 0;

  }
`;

const Navbar = () => {
  const [click, setClick] = useState(false);

  const { scroll } = useLocomotiveScroll();

  const handleScroll = (id) => {
    let elem = document.querySelector(id);
    // console.log(elem);
    setClick(!click);
    scroll.scrollTo(elem, {
      offset: '-120',
      duration: '2000',
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };

  return (
    <NavContainer
      click={+click}
      initial={{ y: `-100%` }}
      animate={{ y: 0 }}
      transition={{ duration: 2, delay: 5 /* 2 */ }}
    >
      <MenuItems
        drag="y"
        dragConstraints={{ left: 0, top: 0 }}
        dragElastic={0.05}
        dragSnapToOrigin
      >
        <MenuBtn onClick={() => setClick(!click)}>
        <div />
        <div />
        <div />
        </MenuBtn>
        <Item
          whileHover={{ scale: 1.1, y: 5 }}
          whileTap={{ scale: 0.9, x: 0 }}
          onClick={() => setClick(!click)}
          style={{transform: "none",
            marginLeft: "5rem",
            fontSize: "2rem",
            marginBottom: "-6rem",
            marginTop: "-2rem"}}
        >
          {' '}
          <Link style={{fontFamily: "Gotham", marginRight: "72px"}} to="/">x</Link>
        </Item>     
        <Item
          whileHover={{ scale: 1.1, x: -5 }}
          whileTap={{ scale: 0.9, x: 0 }}
          onClick={() => handleScroll('.home')}
        >
          {' '}
          <Link to="/" >Ho<u>me</u></Link>
        </Item>
        <Item
          whileHover={{ scale: 1.1, x: -5 }}
          whileTap={{ scale: 0.9, x: 0 }}
          onClick={() => handleScroll('#shop')}
        >
          {' '}
          <Link to="/" >Mamopla<u>stía</u></Link>
        </Item>
        <Item
          whileHover={{ scale: 1.1, x: -5 }}
          whileTap={{ scale: 0.9, x: 0 }}
          onClick={() => handleScroll('.new-arrival')}
        >
          {' '}
          <Link to="/" >Servic<u>ios</u></Link>
        </Item>
       
        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
          onClick={() => handleScroll('.about')}
        >
          <Link to="/" >abo<u>ut</u></Link>
        </Item>

        <Item
          whileHover={{ scale: 1.1, y: -5 }}
          whileTap={{ scale: 0.9, y: 0 }}
          onClick={() => handleScroll('.contact')}
        >
          {' '}
          <Link to="/">Conta<u>cto</u></Link>
        </Item>
      </MenuItems>
    </NavContainer>
  );
};

export default Navbar;
