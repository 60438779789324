import React, { Suspense } from 'react';
import styled from 'styled-components';
import { isBrowser, isMobile } from 'react-device-detect';
/* import HeaderMobile from './HeaderMobile';
import LogoMobile from '../components/LogoMobile'; */

import CoverVideoMobile from '../components/CoverVideoMobile';

const CoverVideo = React.lazy(() => import('../components/CoverVideo'));
const Navbar = React.lazy(() => import('../components/Navbar'));
const Logo = React.lazy(() => import('../components/Logo'));


const Section = styled.section`
  position: relative;
  min-height: 100vh;
overflow: hidden;

`;

const Home = () => {
  return (
    <Section  id="home" className="home">
      <Suspense fallback={<></>}>
        {
          /* isBrowser &&  */
          <Logo />
        }
        {/* {
          isMobile && 
          <LogoMobile  />
        } */}
        
        {/* {
          isMobile && 
          <NavbarMobile  />
        } */}
        {
          isBrowser &&  
          <CoverVideo  />
        }
        {
          isMobile && 
          <CoverVideoMobile  />
        }
      </Suspense>
    </Section>
  );
};

export default Home;
