import { motion } from 'framer-motion';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, {  useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import img1 from '../assets/Images/servicios/001_izquierda.webp';
import img2 from '../assets/Images/servicios/002_derecha.webp';
import img3 from '../assets/Images/servicios/001_SCROL.webp';
import img4 from '../assets/Images/servicios/002_SCROLL.webp';

const Section = styled.section`
  min-height: 110vh;
  /* height: auto; */
  width: 100%;
  margin: 0;
  /* height: 300vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #d4d4d4;  
`;

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  height: 90vh;
  box-shadow: 0 0 0 6vw #d4d4d4;
  border: 2px solid black;
  z-index: 11;
  @media (max-width: 70em) {
  width: 40vw;
    height: 80vh;
  }
  @media (max-width: 64em) {
  width: 50vw;
  box-shadow: 0 0 0 60vw ${(props) => props.theme.text};
    height: 80vh;
  }
  @media (max-width: 48em) {
    width: 90vw;
    height: 80vh;
  }
  @media (max-width: 748px) {
    box-shadow: 0 0 0 40vw #d4d4d4;
  }
`;

const Container = styled.div`
  position: absolute;
  margin: -1px;
  top: 2em;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 25vw;
  height: auto;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 64em) {
    width: 30vw;
    top: 10em;
  }
  @media (max-width: 48em) {
    width: 40vw;
    top: 10em;
  }
  @media (max-width: 30em) {
    width: 60vw;
    top: 10em;
    padding-bottom: 200px; 
  }
`;

const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: 'Kaushan Script';
  font-weight: 300;
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.body};
  text-shadow: 1px 1px 1px ${(props) => props.theme.text};
  position: absolute;
  top: 2rem;
  left: 14rem;
  z-index: 15;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  
  }
`;
const Title2 = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: 'Butler';
  font-weight: 300;
  color: #c4a239;
  position: absolute;
  top: 2rem;
  right: 14rem;
  z-index: 15;
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxl};
  
  }
`;
const Text = styled.div`
  width: 20%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: absolute;
  padding: 2.5rem;
  top: -0.9em;
  right: 4em;
  z-index: 11;
  @media (max-width: 48em) {
    right: 10px;
  }
 
`;

const ContainerLeft = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-75%, 0%);
  width: 25vw;
  height: auto;
  margin-left: 0.5em;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 64em) {
  width: 30vw;
  }
  @media (max-width: 48em) {
  width: 40vw;
  visibility: hidden;
  }
  @media (max-width: 30em) {
  width: 60vw;
  }
`;
const ContainerRight = styled.div`
  position: absolute;
  right: 50%;
  margin-right: 0.5em;
  transform: translate(75%, 0%);
  width: 25vw;
  height: auto;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 64em) {
  width: 30vw;
  }
  @media (max-width: 48em) {
  width: 40vw;
  visibility: hidden;
  }
  @media (max-width: 30em) {
  width: 60vw;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
  h2 {
  }
  img {
    width: 100%;
    height: auto;
    z-index: 5;
  }

  @media (max-width: 30em) {
    margin: 0rem -5px 5rem
  }
  
`;
const Photos = ({ img, name }) => {
  return (
    <Item>
      <img width="400" height="600" src={img} alt={name} />
      <h2 style={{fontFamily: 'Gotham-Medium', marginTop: "1em"}}>{name}</h2>
    </Item>
  );
};

const ItemList = styled.li`
  font-family: 'Montserrat Thin';  
  font-size: ${(props) => props.theme.fontxl};
  font-weight: 300;
  line-height: normal;

  @media (max-width: 48em) {
    font-size: 1.6em;
  }
`;

const List = styled.ul`
  list-style: none;
  font-family: 'Montserrat Medium';  
  margin-top: -3em;
  ${ItemList} {
    margin-bottom: 2px;
  }

  @media (max-width: 48em) {
    padding-left: 2rem;
    margin-left: -3rem;
  }
`;

const NewArrival = () => {
   gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  const ScrollingRef = useRef(null);


  useLayoutEffect(() => {
    let element = ref.current;

    let scrollingElement = ScrollingRef.current;
    let t1= gsap.timeline();
    setTimeout(() => {
      let mainHeight = scrollingElement.scrollHeight;
      element.style.height = `calc(${mainHeight / 4}px)`;
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top top',
          end: 'bottom+=100% top-=100%',
          scroller: '.App', //locomotive-scroll
          scrub: 1,
          pin: true,
          // markers: true,
        },
        ease: 'none',
      });

      t1.fromTo(
        scrollingElement,
        {
          y: '0',
        },
        {
          y: '-78%',
          scrollTrigger: {
            // id: `section-${index + 1}`,
            trigger: scrollingElement,
            start: 'top top',
            end: 'bottom top',
            scroller: '.App',
            scrub: 1,
            // markers: true,
          },
        },
      );

      ScrollTrigger.refresh();
    }, 1000);
    ScrollTrigger.refresh();

    return () => {
      t1.kill();
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section  ref={ref} id="fixed-target" className="new-arrival">
      <Overlay />

      <Title
        data-scroll data-scroll-speed="-2" data-scroll-direction="horizontal"
      >
        <ContainerLeft>

        <Photos img={img1} />
        </ContainerLeft>
      </Title>

      <Container ref={ScrollingRef}>
      <Photos img={img3} name="EN QUIRÓFANO" data-scroll data-scroll-speed="-4" />
      <List>
        <ItemList>Cirugía mamaria</ItemList>
        <li>Aumento mamario (inclusión de prótesis mamarias)</li>
        <li>Pexia mamaria (levantamiento)</li>
        <li>Pexia mamaria con prótesis</li>
        <li>Recambio de prótesis mamarias</li>
        <li>Reducción mamaria</li>
        <li>Reconstrucción Mamaria</li>
        <li>Explantación de prótesis mamarias</li><br />
        
        <ItemList>Cirugía facial</ItemList>
        <li>Rinoplastia</li>
        <li>Lifting facial</li>
        <li>Blefaroplastia (superior e inferior)</li>
        <li>Lifting de labios</li><br />

        <ItemList> Cirugía de contorno corporal</ItemList>
        <li>Abdominoplastia (dermolipectomía)</li>
        <li>Mini Abdominoplastia</li>
        <li>Liposucción</li>
        <li>Abdominoplastia endoscópica (reparación endoscópica de diastasis de rectos)</li><br />

        <ItemList>Transgénero</ItemList>
        <li>Masculinización de tórax</li>
        <li>Feminización de tórax </li><br />

      </List>

      <Photos img={img4} name="EN CONSULTORIO" />
      <List>
        <ItemList>Rellenos con ácido hialurónico</ItemList>
        <li>Labios</li>
        <li>Rinomodelación</li>
        <li>Ojeras</li>
        <li>Surcos nasogenianos</li>
        <li>Pómulos</li>
        <li>Mentón</li>
        <li>Contorno mandibular</li>
        <li>Código de barras</li> <br />

        <ItemList>Bioestimuladores</ItemList>
        <li>Radiesse (hidroxiapatita de calcio)</li>
        <li>Long Lasting</li> <br />

        <ItemList>Neuromodulación (toxina botulínica)</ItemList>
        <li>Frente</li>
        <li>Entrecejo</li>
        <li>Patas de gallo</li>
        <li>Sonrisa gingival</li>
        <li>Bruxismo</li> <br />

        <ItemList>Tratamiento capilar</ItemList>
        <li>Plasma rico en plaquetas</li>
        <li>Masoterapia</li>
        <li>Implante de pelo (técnica FUE)</li> <br />
      </List>
      </Container>
      <Title2
        data-scroll data-scroll-speed="2" data-scroll-direction="horizontal"
      >
        <Text>SERVICIOS</Text>
        
        <ContainerRight>
          <Photos img={img2} />
        </ContainerRight>
      </Title2>
      {/* <Text data-scroll data-scroll-speed="-4">
        There is new collection available for cool clothes in all sizes. This collection
        is a great way to find a new look for you. It offers a variety of cool apparel
        styles to fit your taste, while you can also find some cool clothes that you can
        wear everyday.
        <br />
        <br />
        The first line of clothing you will see on this collection is for men. The
        collection also includes three new styles for women.
        <br />
        <br />
        Give it a try and experience a new look.
      </Text> */}
    </Section>
  );
};

export default NewArrival;