import React, { useState } from "react";
import styled from "styled-components";
import img1 from "../assets/Images/about/juntos.webp";
import img2 from "../assets/Images/about/agus.webp";
import img3 from "../assets/Images/about/adriano.webp";

const Section = styled.section`
  min-height: 102vh;
  margin: 0 auto;
  width: 100vw;
  padding-right: 7em;
  /* background: linear-gradient(#1c1c1c, 96%, #d4d4d4); */
  background-image: linear-gradient(
    0deg,
    hsl(0deg 0% 83%) 0%,
    hsl(344deg 0% 57%) -1%,
    hsl(344deg 0% 32%) 11%,
    hsl(0deg 0% 11%) 82%
  );
  position: relative;
  margin-top: -5px;

  display: flex;
  @media (max-width: 48em) {
    width: 90vw;
  }

  @media (max-width: 30em) {
    width: 100vw;
  }
  /* justify-content: center;
  align-items: center; */
`;

const Left = styled.div`
  width: 65%;
  font-size: ${(props) => props.theme.fontmd};
  font-weight: 300;
  position: relative;
  padding-right: 300px;
  font-family: Montserrat Thin;
  text-align: justify;
  color: white;
  margin-left:10em;
  top: 22em;

  h5{
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 0.3rem;
    font-family: Montserrat Medium;
  }

  @media (max-width: 64em) {
    width: 80%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 2rem;
    width: 70%;
  }
`;

const Right = styled.div`
  width: 75%;
  padding-top:5em;
  right: 5%;
  position: relative;
  /* min-height: 100vh; */

  img {
    width: 100%;
    height: auto;
  }

  .small-img-1 {
    width: 35%;
    position: absolute;
    right: 80%;
    top: 50%;
  }
  .small-img-2 {
    width: 35%;
    position: absolute;
    left: 85%;
    top: 5%;
  }
  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    .small-img-1 {
      width: 30%;
      height: auto;
      left: 5%;
      bottom: 10%;
    }
    .small-img-2 {
      width: 30%;
      height: auto;

      position: absolute;
      left: 60%;
      bottom: 20%;
    }
  }
`;

const Subtitle = styled.div`
  color: #c4a239;
  font-size: 0.4em;
  text-align: right;
  font-family: 'Butler';
`;

const Title = styled.h1`
  font-size: 7.5rem;
  font-family: "Butler";
  font-weight: 300;
  /* text-transform: capitalize; */
  color: white;
  position: absolute;
  left: 2em;
  z-index: 5;

  span {
    display: inline-block;
  }

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0;
    left: 0%;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;

const Instagram = styled.div`
  color:#c4a239;
  text-decoration: none;
  &:hover,
  &:focus{
      color: white;
  };
  &:active{
      color: white;
  };
  @media(max-width: 700px) {
          display: none;
  }
`;

const About = () => {
  const [textoAbout, setTextoAbout] = useState(true);
  const [descripcion1, setDescripcion1] = useState(false);
  const [descripcion2, setDescripcion2] = useState(false);
  
  const handleClick = () => {
    setTextoAbout(false);
    setDescripcion1(true);
    setDescripcion2(false);
  }
  const handleClick2 = () => {
    setTextoAbout(false);
    setDescripcion1(false);
    setDescripcion2(true);
  }
  const handleClick3 = () => {
    setTextoAbout(true);
    setDescripcion1(false);
    setDescripcion2(false);
  }
  return (
    <Section id="fixed-target" className="about">
      
      <p style={{color:"white", fontFamily:"Montserrat Medium", paddingLeft:"46em", position:"absolute", top:"82%", width:"max-content", fontSize:"1em", zIndex:"5", right:"22%"}}>Seguinos en Instagram<br /><a href="https://www.instagram.com/dres.soracemendoza"> <Instagram>@dres.soracemendoza</Instagram></a></p>

      <Title
      >
        ABOUT
        <Subtitle>
          DRES. <br />
          SORACE & <br />
          MENDOZA
        </Subtitle>
      </Title>  
      <Left >        
        { textoAbout &&
          <div>
            Somos los Dres. Adriano y Agustín, socios y colegas desde la residencia de cirugia general en el Hospital de Vicente López (Dr. Bernardo Houssay). 
            Hoy, como Cirujanos Plásticos, participamos activamente como miembros en diferentes sociedades científicas; Sociedad Argentina de Cirugía Plástica,
            Estética y Reparadora, la Sociedad de Cirugía Plástica de Buenos Aires, la Asociación Argentina de Cirugía Estética y la Asociación Argentina de Cirugía.
          </div>
        }
              
        {descripcion1 && 
            
          <div>
            <h1>Agustín Mendoza</h1>
            Soy Agustín Mendoza, médico cirujano egresado de la UBA, especializado en cirugía plástica y formo parte del staff profesional en Sorace & Mendoza. Desarrollo mi actividad como médico de staff del servicio de cirugía plástica del Hospital Houssay y participo activamente como miembro de diferentes sociedades científicas. 
          </div>}

        {descripcion2 && 
            
          <div>
            <h1>Adriano Sorace</h1>
            Soy Adriano Sorace, médico cirujano especializado en cirugía plástica, egresado de la UBA y formo parte del equipo que trabaja en Sorace & Mendoza. Actualmente me desempeño como médico de staff de cirugía plástica en la Trinidad de Palermo y en el Centro Médico Galeno Barrio Norte, a la vez que participo activamente como miembro de diferentes sociedades científicas.
          </div>}

      </Left>

      <Right>
      <button onClick={handleClick3}>
        <img width="400" height="600" src={img1} alt="About Us" z-index="5" />
      </button>
        
      <button onClick={handleClick}>        
        <img
          width="400"
          height="600"
          className="small-img-1"
          src={img2}
          alt="About Us"
        />
      </button> 
      <button onClick={handleClick2}>
        <img
          width="400"
          height="600"
          className="small-img-2"
          src={img3}
          alt="About Us"
          />
      </button>

      </Right>
      
    </Section>
  );
};

export default About;
