import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Iframe from 'react-iframe';
import { WhatsApp } from 'react-whatsapp-component'; 
import Logo from "../assets/Svgs/logo-blanco.svg";

const FormStyled = styled.div`
  padding: 40px 100px;
  @media (max-width: 40em) {
    padding: 0 40px 20px;
  }
  width: 100%;
  margin-top: 10rem;
`;

const Section = styled.section`
  min-height: 100vh;
  margin: 0 auto;
  background-color: #d4d4d4;
  position: relative;

  display: flex;
  @media (max-width: 48em) {
    width: 100vw;
    min-height: 88vh;
  }

  /* justify-content: center;
  align-items: center; */
`;


const Title = styled.h1`
  font-size: 6em;
  font-family: "Butler";
  font-weight: 300;
  /* text-transform: capitalize; */
  color: #c4a239;
  position: absolute;
  top: 4rem;
  right: 20%;
  z-index: 5;

  span {
    display: inline-block;
  }

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0.5em;
    left: 0.5em;
  }
  @media (max-width: 48em) {
    font-size: 3.5em;
  }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 48em) {
      display: block;
    }
`;

const Left = styled.div`
    margin-top: 10rem;
    width: 52%;
    text-align: right;    
    font-family: Montserrat Medium;
    padding-right: 2em;
    font-weight: 600;
    h4{
      font-family: Montserrat Medium;
    }
    p{
      font-family: Montserrat Thin;
    }

    @media (max-width: 48em) {
      text-align: center;
      font-family: Montserrat Medium;
      padding-right: 0em;
      font-weight: 600;
      width: auto;
      margin-top: 0;
    }

`;

const Right = styled.div`
    margin-top: 9rem;
    right: 1em;
    width: 100%;
    font-family: Montserrat Medium;

    h4{
      font-family: Montserrat Medium;
    }
    p{
      font-family: Montserrat Thin;
    }

    @media (max-width: 64em) {
      display: none;
      margin-top: -18em;

      /* WhatsApp{
        visibility: visible;
      } */
    }

    @media (max-width: 48em) {
      visibility: hidden;
      margin-top: -18em;
    }
`;

const Instagram = styled.div`
  color:#c4a239;
  text-decoration: none;
  font-size:1.2em;
  &:hover,
  &:focus{
      color: white;
  };
  &:active{
      color: white;
  };
  @media(max-width: 700px) {
          display: none;
  }
`;

const Texto = styled.div`    
    width: 35%;
    position:absolute;
    margin-bottom:5em;
    font-family: Montserrat Thin;
    font-size: ${(props) => props.theme.fontxxl};
    font-weight: 300;
    left: 2em;
    text-align: center;

    @media (max-width: 48em) {
      font-size: 1.5em;
      margin-top: 4em;
      inline-size: fit-content;
    }
    @media (max-width: 48em) {
      font-size: 1.5em;
      margin-top: 4em;
      inline-size: fit-content;
      left: 1.5em;
    }

`;
const Logo2 = styled.div`
  display: none;

  @media (max-width: 48em) {
    display: block;
  }
`;
const ImgMobile = styled.div`

display: none;

@media (max-width: 48em) {
  background-color: ${(props) => props.theme.body};
  height: 10em;
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  visibility: visible;
}
//XR
    @media (max-width: 48em) { 
      margin-top: 0;
    }
    //SE
    @media (max-width: 376px) {
      margin-bottom: 4.5em;
    }
    @media (min-width: 26em) {
      margin-top: 3em;
    }
    //S8+
    @media (max-width: 22.5em) {
      margin-bottom: 5.5rem;
    }
`;

function Contact() {

  const [formStatus, setFormStatus] = React.useState('Enviar')

  function handleSubmit(event) {
    event.preventDefault();

    fetch("https://formsubmit.co/ajax/info@soracemendoza.com", {
      method: "POST",
      headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
      },
      body: JSON.stringify({
              name: event.target.elements.name.value,
              email: event.target.elements.name.value,
              comments: event.target.elements.comments.value
          })
    })
    .then(response => {
      if(response.status === 200){
        event.target.elements.name.value = '';
        event.target.elements.email.value = '';
        event.target.elements.comments.value = '';
        setFormStatus("Enviado!")
      }
    })
    .catch(error => console.log(error));        
  }

  return (
    <Section id="fixed-target" className="contact">
      <Title

      >
        CONTACTO
      </Title>
      
      <Container>
        

        <Texto>RESERVÁ TU CONSULTA</Texto>
      <FormStyled>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-4">
                  <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                      required
                      type="text"
                      placeholder="Nombre"
                      name="name"
                  />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Mail</Form.Label>
                  <Form.Control
                      required
                      type="email"
                      placeholder="ejemplo@ejemplo.com"
                      name="email"
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              </Row>

              <Form.Group className="mb-3" controlId="formBasicPassword" styles={{height: "100px"}}>
                  <Form.Label>Mensaje</Form.Label>
                  <Form.Control type="textarea" name="comments" />
              </Form.Group>

              <Form.Control type="hidden" name="_next" value="https://soracemendoza.com" />
              <Form.Control type="hidden" name="_captcha" value="false" />

              <Button variant="dark" type="submit">
                {formStatus}
              </Button>
            </Form>
        </FormStyled> 
      <Left>
      
            <div className="info">

              <div className="address">
                {/* <div className="d-flex">
                <Icon icon="material-symbols:location-on" width="30" />
                <h4>Dirección:</h4>
                </div> */}
                <p><a href="https://www.instagram.com/dres.soracemendoza"> <Instagram>@dres.soracemendoza</Instagram></a></p>

                <p>Av. del Libertador 2337, Olivos. <br />
                Entre Corrientes y Uribelarrea.</p>
              </div>

              <div className="email">
                {/* <div className="d-flex">
                    <Icon icon="material-symbols:mail-rounded" width="30" />
                    <h4>Correo:</h4>
                </div> */}
                <p>info@soracemendoza.com</p>
              </div>

              <div className="phone">
                {/* <div className="d-flex">
                    <Icon icon="material-symbols:call" width="30" />
                    <h4>Telefono:</h4>
                </div> */}
                <p style={{marginLeft:"-2px"}}>Turnos WhatsApp: +549113378-4025
                <Logo2>
                  <WhatsApp style={{position: "absolute", right: "8px",bottom: "295px", width: "50px", height: "50px"}} phone="+5491133784025" text="Hola, quiero recibir mas informacion por favor." />
                </Logo2>
                </p>
              </div>
            </div>
            </Left>
            <ImgMobile>
              <img width="170" height="170" src={Logo}  />              
            </ImgMobile>

            <Right>
              <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3287.75399038802!2d-58.48089498495046!3d-34.50912058048399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb143bb8b6a89%3A0x8e670bb5227656f6!2sAv.%20del%20Libertador%202337%2C%20Olivos%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1678417295416!5m2!1ses-419!2sar" frameborder="0"
                width="90%"
                height="300px"
                id=""
                className=""
                display="block"
                position="relative"/>
            </Right>



      
                 

      </Container>            
    </Section>
  );
}

export default Contact;