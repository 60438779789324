import "locomotive-scroll/dist/locomotive-scroll.css";

import { AnimatePresence } from "framer-motion";
import React, { useRef, useState, useEffect } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import styled, { ThemeProvider } from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';

import Loader from "./components/Loader";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import About from "./sections/About";
import Footer from "./sections/Footer";
import Home from "./sections/Home";
import Marquee from "./sections/Marquee";
import NewArrival from "./sections/NewArrival";
import Shop from "./sections/Shop";
//import ShopMobile from "./sections/ShopMobile";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import { isBrowser, isMobile } from 'react-device-detect';
import Navbar from "./components/Navbar";
import Contact from "./sections/Contact";
import AboutMobile from "./sections/AboutMobile";
//import NewArrivalMobile from "./sections/NewArrivallMobile";

function App() {
  // useLocoScroll();
  const containerRef = useRef(null);
  const [Loaded, setLoaded] = useState(false);

  const Section = styled.section`
  background-color: ${(props) => `rgba(${props.theme.textRgba},0.7)`};
  `;

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
  }, []);

  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <LocomotiveScrollProvider
          options={{
            smooth: true,
            // ... all available Locomotive Scroll instance options
            smartphone: {
              smooth: true,
            },
            tablet: {
              smooth: true,
            },
          }}
          watch={
            [
              //..all the dependencies you want to watch to update the scroll.
              //  Basicaly, you would want to watch page/location changes
              //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
            ]
          }
          containerRef={containerRef}
        >
          <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
          <main className="App contenedor" data-scroll-container ref={containerRef}>
            <ScrollTriggerProxy />
            <AnimatePresence>
              {Loaded ? null : <Loader />}

              <Home key="home"  />
              <Shop key="Shop"  />
              <NewArrival key="new arrival"  />
              <Marquee key="marquee"  />
              { isMobile && <AboutMobile key="about" /> }
              { isBrowser && <About key="about"  /> }
              <Contact key="contact"  />
              { isBrowser && <Footer key="footer"  /> }
            </AnimatePresence>
          </main>
          <Section>
            <Navbar />
          </Section>
        </LocomotiveScrollProvider>
      </ThemeProvider>
    </>
  );
}

export default App;



