import { motion } from "framer-motion";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import img4 from "../assets/Images/mamoplastias/001AUMENTO.webp";
import img5 from "../assets/Images/mamoplastias/002REDUCCION.webp";
import img6 from "../assets/Images/mamoplastias/003Reconstruccion.webp";
import img7 from "../assets/Images/mamoplastias/004LEVANTAMIENTO .webp";
import img8 from "../assets/Images/mamoplastias/005GENERO.webp";
import bn_mamoplastia from "../assets/Images/mamoplastias/bn_mamoplastia.webp";
/* import { Link } from 'react-router-dom';
import { useLocomotiveScroll } from 'react-locomotive-scroll'; */

const Section = styled(motion.section)`
  min-height: 110vh;
  height: auto;
  /* width: 80vw; */
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #d4d4d4;

  position: relative;

  /* background-color: orange; */
`;

const MamoplastiaTitle = styled.p`
  
  @media (max-width: 64em) {
    visibility: hidden;
  }
  @media (max-width: 48em) {
    visibility: hidden;
    margin-bottom: -1rem;
  }
  @media (max-width: 30em) {
    visibility: hidden;
    margin-bottom: -2rem;
  }
`;

const Title = styled.h1`
  font-size: 7em;
  font-family: 'Butler';
  font-weight: 300;
  margin-left: 0.5em;
  z-index: 12;
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.text};



  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxl};
    margin-left: -1.5em;
    margin-top: 1em;
  }
  @media (max-width: 30em) {
    font-size: 2.8em;
    margin-top: 0.5em;
    padding-left: 1rem;
  }
    //S8+
    @media (max-width: 22.5em) {
      padding-left: 0;
    }
`;

const Text = styled.div`
  font-size: "1.1em";
  font-weight: 300;
  /* text-transform: capitalize; */
  color: ${(props) => props.theme.text};
  width: 22em;
  position: absolute;
  left: 3%;
  z-index: 11;
  text-align: left;
  font-family: Montserrat Thin;

  @media (max-width: 64em) {
    font-size: 1rem;
    width: 56%;
    margin-top: -3em;
  }
  @media (max-width: 48em) {
    font-size: .9rem;
    width: 56%;
    margin-top: -2em;
  }
  @media (max-width: 30em) {
    font-size: .8rem;
    width: 60%;
    margin-top: -3em;
    left: 0.3em;
  }
 
`;

const Left = styled.div`
  width: 37%;
  /* background-color: ${(props) => props.theme.body} !important; */
  color: ${(props) => props.theme.text};

  height: 100vh;
  z-index: 10;

  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2em;

  img {
    -webkit-filter: grayscale(1); /* Webkit */
    filter: gray; /* IE6-9 */
    filter: grayscale(1); /* W3C */
    min-height: 100vh;
  }

  h5{
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 500;
    width: 80%;
    margin-bottom: 0.3em;
    font-family: Montserrat Thin;
  }

  div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  p {
    font-size: ${(props) => props.theme.fontlg};
    width: 80%;
    margin: 0 auto;
    font-family: Montserrat Thin;
  }

  @media (max-width: 64em) {
    z-index: 10;
    p {
      font-size: ${(props) => props.theme.fontmd};
    }
  }

  @media (max-width: 48em) {
    width: 40%;
    z-index: 10;
    p {
      font-size: ${(props) => props.theme.fontsm};
    }
    img {
      visibility: hidden;
      /* object-fit: cover;
      object-position: bottom;
      z-index: 13; */
    }
  }
  @media (max-width: 30em) {
    z-index: 10;
    p {
      font-size: ${(props) => props.theme.fontxs};
    }
  }
`;
const Right = styled.div`
  /* width: 65%; */
  position: absolute;
  left: 35%;
  min-height: 100vh;
  background: #d4d4d4;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  button{
    border: none;
  }

  @media (max-width: 48em) {
    min-height: 90vh
  }
`;

// const ImgContainer = styled.section`
//   width: 50%;
//   height: 50vh;
//   position: relative;
//   video {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     object-position: center;    
//   }
// `;
const BoxTransparent = styled.section`
  opacity: .5;
  background-color: rgb(0, 0, 0);
  height: 36em;
  width: 24em;
  position: absolute;
  margin-top: 8em;
  padding: 1.5em;
  border-radius: 20px;

  @media (max-width: 48em) {
    opacity: 1;
    background-color: #1c1c1c;
    height: 65em;
    width: 12.5em;
    margin-top: 0em;
    margin-left: -4px;
    border-radius: 0 10px 10px 0;
  }
`;

const Item = styled(motion.div)`
  display: inline-block;
  width: 36em;
  padding-top: 3em
  /* background-color: black; */
  margin-right: 2em;
  img {
    width: 70%;
    height: auto;
    cursor: pointer;
    margin-top: 7em;

    @media (max-width: 98em) {
      margin-top: 7em;
      width: 65%;
    }
    @media (max-width: 96em) {
      width: 63%;
    }

    //XR
    @media (max-width: 48em) { 
      margin-top: -9em;
    }
    //SE
    @media (max-width: 376px) {
      margin-top: 3em;
    }

    //S8+
    @media (max-width: 22.5em) {
      margin-top: 0em;
    }
    
  }

  h2 {
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    font-family: Gotham Thin;
    position: absolute;
    top: 18em;
    left: 6em;
    color: white;
  }

  @media (max-width: 48em) {
    width: 24em;
  }
  @media (max-width: 30em) {
    width: 21em;
  }
  
`;
//data-scroll data-scroll-speed="-2" data-scroll-direction="horizontal"
const Product = ({ img, title = "", }) => {
  return (
    // x: 100, y: -100
    <Item
      initial={{ filter: "grayscale(100%)" }}
      whileInView={{ filter: "grayscale(0%)" }}
      transition={{ duration: 0.2 }}
      viewport={{ once: true, amount: "all" }}
    >
      <img src={img} alt={title} />
    </Item>
  );
};

/* const ButtonMenu = styled.div`
      position: relative;
      display: block;
      bottom: 1.5625rem;
      right: -33.4375rem;
      z-index: 999;
      background: rgba(255, 255, 255, .9);
      height: 2.375rem;
      width: 2.375rem;
      line-height: 2.25rem;
      text-align: center;
      font-size: .875rem;
      border-radius: 100%;
      box-shadow: 0 0 0 5px rgba(0, 0, 0, .05);
      transition: .5s all ease;
      -webkit-transform: translateY(39.25rem);
      transform: tran;
      transform: translateY(38.25rem);
      color: #788487;
`; */


const Shop = () => {
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  const Horizontalref = useRef(null);
  const [textoGeneral, setTextoGeneral] = useState(true);
  const [textoAumento, setTextoAumento] = useState(false);
  const [textoReduccion, setTextoReduccion] = useState(false);
  const [textoLevantamiento, setTextoLevantamiento] = useState(false);
  const [textoReconstruccion, setTextoReconstruccion] = useState(false);
  const [textoTransgenero, setTextoTransgenero] = useState(false);

  const clickImg1 = () => {
    if (textoAumento) {
      setTextoGeneral(true);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    } else {
      setTextoGeneral(false);
      setTextoAumento(true);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    }
  }

  const clickImg2 = () => {
    if (textoReduccion) {
      setTextoGeneral(true);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    } else {
      setTextoGeneral(false);
      setTextoAumento(false);
      setTextoReduccion(true);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    }    
  }

  const clickImg3 = () => {
    if (textoLevantamiento) {
      setTextoGeneral(true);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    } else {
      setTextoGeneral(false);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(true);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    }
    
  }

  const clickImg4 = () => {
    if (textoReconstruccion) {
      setTextoGeneral(true);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    } else {
      setTextoGeneral(false);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(true);
      setTextoTransgenero(false);
    } 
  }
  const clickImg5 = () => {
    if (textoTransgenero) {
      setTextoGeneral(true);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(false);
    } else {
      setTextoGeneral(false);
      setTextoAumento(false);
      setTextoReduccion(false);
      setTextoLevantamiento(false);
      setTextoReconstruccion(false);
      setTextoTransgenero(true);
    }
  }




  useLayoutEffect(() => {

    let element = ref.current;

    let scrollingElement = Horizontalref.current;

    let pinWrapWidth = scrollingElement.offsetWidth;
    let t1 = gsap.timeline();

    setTimeout(() => {
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: "top top",
          end: `${pinWrapWidth} bottom`,
          scroller: ".App", //locomotive-scroll
          scrub: 1,
          pin: true,
          // markers: true,
          // anticipatePin: 1,
        },
        height: `${scrollingElement.scrollWidth}px`,
        ease: "none",
      });

      t1.to(scrollingElement, {
        scrollTrigger: {
          trigger: scrollingElement,
          start: "top top",
          end: `${pinWrapWidth} bottom`,
          scroller: ".App", //locomotive-scroll
          scrub: 1,
          // markers: true,
        },
        x: -pinWrapWidth,

        ease: "none",
      });
      ScrollTrigger.refresh();
    }, 1000);
    ScrollTrigger.refresh();

    return () => {
      t1.kill();
      ScrollTrigger.kill();
    };
  }, []);

  /* const { scroll } = useLocomotiveScroll();

  const handleScroll = (id) => {
    let elem = document.querySelector(id);
    // console.log(elem);
    scroll.scrollTo(elem, {
      offset: '-100',
      duration: '2000',
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  }; */

  return (
    <Section ref={ref} id="shop">
      {/* <Overlay /> */}        
        <Title data-scroll data-scroll-speed="-2" data-scroll-direction="horizontal">
          MAMOPLASTIA        
        </Title>
      <Text >
          <BoxTransparent />
        <Text >           
        <MamoplastiaTitle><p style={{fontFamily:"Montserrat Medium", fontSize: "2.5em", paddingTop:"4em"}} >MAMOPLASTIA</p></MamoplastiaTitle>
          
          <br />
          {textoGeneral && 
          
            <div style={{paddingTop:"6em"}}>Lo último en aumento mamario. <br /> Con nuestra técnica de acelerada recuperación se reducen notablemente <br />las molestias postquirúrgicas</div>            
          
          }
          {textoAumento && <div style={{paddingTop:"3em"}}>Una cirugía de aumento mamario de rápida recuperación es un procedimiento quirúrgico en el que se utilizan técnicas avanzadas para la inclusión de prótesis mamarias, buscando como objetivo principal minimizar el dolor y reducir el tiempo de recuperación. </div>}
          {textoReduccion && <div>La cirugía de reducción mamaria tiene por objetivo disminuir el volumen mamario, al mismo tiempo que mejora su forma y posición. Esta cirugía es comúnmente recomendada para mujeres que presentan un volumen mamario excesivo y del cual se desprenden múltiples problemas de salud como dolor de espalda, cuello y hombros, irritación de la piel y dificultad para hacer actividad física.</div>}
          {textoLevantamiento && <div>La mastopexia, es un procedimiento quirúrgico en el que se levantan y reafirman las mamas caídas o con mucha flacidez. Se elimina el exceso de piel y tejido mamario y se reposicionan los pezones y las aréolas para que estén más altos y en una posición más juvenil. Se debe tener en cuenta que el levantamiento mamario no aumentará el tamaño de las mamas. Se puede combinar con una cirugía de aumento mamario para obtener mejores resultados.</div> }
          {textoReconstruccion && <div>La cirugía de reconstrucción mamaria es un procedimiento quirúrgico que restaura la estructura y apariencia mamarias generalmente luego de una mastectomía (cirugía utilizada para extirpar una mama). Este procedimiento se realiza para ayudar a las mujeres a recuperar su autoestima y confianza después de un diagnóstico de cáncer de mama.</div>}
          {textoTransgenero && <div>La cirugía transgénero incluye varios tipos de procedimientos quirúrgicos para modificar los rasgos físicos de una persona para que se correspondan más con su identidad de género. Entre ellos se encuentran los distintos tipos de feminización y masculinización torácica. La elección de la cirugía es una decisión personal que se toma en consulta con profesionales de la salud capacitados en la atención médica transgénero. </div>}
          
        </Text>
        
      </Text> 
      {/* <Overlay /> */}
      <Left >
      <img
          className="d-block w-100"
          src={bn_mamoplastia}
          alt="First slide"
        />        
      </Left>
      <Right data-scroll ref={Horizontalref}>
        
      <button onClick={clickImg1} style={{backgroundColor:"#d4d4d4"}}>
        <Product img={img4} />
        <h2 style={{transform:"translate(0%,-100%)", color:"white", fontFamily: 'Gotham'}}> <div style={{fontSize: "0.5em"}}>(<img src="https://icongr.am/clarity/add.svg?size=10&color=ffffff" alt="" />)</div>AUMENTO</h2>
      </button>      
      <button onClick={clickImg2} style={{backgroundColor:"#d4d4d4"}}><Product img={img6} /><h2 style={{transform:"translate(0%,-100%)", color:"white", fontFamily: 'Gotham'}}><div style={{fontSize: "0.5em"}}>(<img src="https://icongr.am/clarity/add.svg?size=10&color=ffffff" alt="" />)</div> REDUCCIÓN</h2></button>
      <button onClick={clickImg3} style={{backgroundColor:"#d4d4d4"}}><Product img={img5} /><h2 style={{transform:"translate(0%,-100%)", color:"white", fontFamily: 'Gotham'}}><div style={{fontSize: "0.5em"}}>(<img src="https://icongr.am/clarity/add.svg?size=10&color=ffffff" alt="" />)</div>LEVANTAMIENTO</h2></button>
      <button onClick={clickImg4} style={{backgroundColor:"#d4d4d4"}}><Product img={img7} /><h2 style={{transform:"translate(0%,-100%)", color:"white", fontFamily: 'Gotham', fontSize: "30px"}}> <div style={{fontSize: "0.5em"}}>(<img src="https://icongr.am/clarity/add.svg?size=10&color=ffffff" alt="" />)</div>RECONSTRUCCIÓN</h2></button>
      <button onClick={clickImg5} style={{backgroundColor:"#d4d4d4"}}><Product img={img8} /><h2 style={{transform:"translate(0%,-100%)", color:"white", fontFamily: 'Gotham'}}><div style={{fontSize: "0.5em"}}>(<img src="https://icongr.am/clarity/add.svg?size=10&color=ffffff" alt="" />)</div> TRANSGÉNERO</h2></button>        
      </Right>      

{/* button goto up */}
      {/* <ButtonMenu
          whileHover={{ scale: 1.1, x: -5 }}
          whileTap={{ scale: 0.9, x: 0 }}
          onClick={() => handleScroll('.home')}
        >
          {' '}
          <Link to="/" ><img src="https://www.eldars.com.ar/assets/images/arrowup.png" /></Link>
      </ButtonMenu> */}
    </Section>
  );
};

export default Shop;
