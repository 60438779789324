import React from 'react';
import styled from 'styled-components';

import img1 from "../assets/Images/mensajes/001.webp";
import img2 from "../assets/Images/mensajes/002.webp";
import img3 from "../assets/Images/mensajes/003.webp";
import img4 from "../assets/Images/mensajes/004.webp";
import img5 from "../assets/Images/mensajes/005.webp";


const Section = styled.section`
  min-height: 100vh;
  margin: 0 auto;
  background-color: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: blue; */
  position: relative;

@media (max-width: 48em){
  width: 100%;
} 

`;

const Container = styled.div`
  min-height: 100vh;
/* width: -webkit-fill-available;
background-image: linear-gradient(
  180deg,
  hsl(0deg 0% 83%) 0%,
  hsl(0deg 0% 79%) -1%,
  hsl(0deg 0% 75%) -2%,
  hsl(0deg 0% 71%) -1%,
  hsl(0deg 0% 67%) 0%,
  hsl(0deg 0% 62%) 2%,
  hsl(0deg 0% 57%) 5%,
  hsl(0deg 0% 51%) 9%,
  hsl(0deg 0% 44%) 16%,
  hsl(0deg 0% 37%) 25%,
  hsl(0deg 0% 27%) 40%,
  hsl(0deg 0% 11%) 82%
); */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 64em){
    justify-content: center;
    padding-top: 2rem;
}
`;

const Banner = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  font-family: 'Open Sans';
  color: black;
  /* position: absolute; */
  white-space: nowrap;
  text-transform: uppercase;
  line-height: 1;


  @media (max-width: 70em){
    font-size: ${(props) => props.theme.fontxxl};
}
@media (max-width: 64em){
    margin: 1rem 0;
}
 
@media (max-width: 48em){
    font-size: ${(props) => props.theme.fontmd};
    margin: 0.5rem 0;

}
@media (max-width: 30em){
    font-size: .9em;
}

  span {
    display: block;
    font-family: Montserrat Medium;

    padding: 1rem 2rem;
    display: flex;
  }
  
  h6{
    font-size: 0.8em;
    text-transform: none;
    padding: 2px 0px 10px 10px;
    color: #c4a239;
    font-family: 'Butler';
    text-transform: uppercase;

    @media (max-width: 30em){
      font-size: 1.2em;
    }
  }

  img{
    width: 80px;
    height: 80px;
    border-radius: 50px;
  }

  div{
    margin-left: 1rem;
    color: white;
  }
`;

const Marquee = () => {
  return (
    <Section>
      <Container id="direction">
        <Banner>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="8"
            data-scroll-target="#direction"
          >
    
            <img src={img1} alt="imag" />

            <div>
              <h6>MECHI LACAVA</h6>
              La recuperación<br /> fue muy rápida.
            </div>
            
          </span>
        </Banner>
        <Banner data-scroll data-scroll-speed="-2" data-scroll-target="#direction">
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-6"
            data-scroll-target="#direction"
          >
            <img src={img2} alt="imag" />

            <div>
              <h6>Micaela Gafarelo</h6>
              Puedo decir que me siento <br />espectacular.
            </div>
            
          </span>
        </Banner>
        <Banner>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="6"
            data-scroll-target="#direction"
          >
            <img src={img3} alt="imag" />

            <div>
              <h6>Natalia Lust</h6>
              Todo fue un placer desde la <br />primer consulta.
            </div>
            
          </span>
        </Banner>
        <Banner>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-4"
            data-scroll-target="#direction"
          >
            <img src={img4} alt="imag" />

            <div>
              <h6>Cintia Olivera </h6>
              La verdad,<br /> nunca senti dolor.
            </div>
            
          </span>
        </Banner>
        <Banner>
          <span
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="6"
            data-scroll-target="#direction"
          >
            <img src={img5} alt="imag" />

            <div>
              <h6>Leila Nadur</h6>
              Me hicieron sentir contenida, <br />confiada y cómoda.
            </div>
            
          </span>
        </Banner>
      </Container>
    </Section>
  );
};

export default Marquee;
