import { motion } from 'framer-motion';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useRef } from 'react';
import styled from 'styled-components';

import img1 from "../assets/Images/about/juntos.webp";
import img2 from "../assets/Images/about/agus.webp";
import img3 from "../assets/Images/about/adriano.webp";

const Section = styled.section`
  min-height: 275vh;
  /* height: auto; */
  width: 100%;
  margin: 0 auto;
  /* height: 300vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* background-color: ${(props) => props.theme.text}; */
  background: rgb(212,212,212);
  /* https://cssgradient.io/ */
  background: linear-gradient(0deg, rgba(212,212,212,1) 0%, rgba(28,28,28,1) 28%);
  margin-top: -5px;


  @media (max-width: 376px) {
    min-height: 330vh;
  }
  @media (min-width: 30em) {
    min-height: 320vh;
    }
`;

const Container = styled.div`
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 25vw;
  height: auto;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 64em) {
    width: 30vw;
  }
  @media (max-width: 48em) {
    width: 60vw;
    padding-top: 5em;
  }
 /*  @media (max-width: 30em) {
    width: 60vw;
    padding-top: 6em;
  } */
`;

const Title = styled(motion.h1)`
  font-size: ${(props) => props.theme.fontxxxl};
  font-family: 'Butler';
  font-weight: 300;
  /* text-transform: capitalize; */
  color: white;
  position: absolute;
  top: 0rem;
  right: 5rem;
  z-index: 15;
  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxl};
  
  }
`;
const Text = styled.div`

  font-family: Montserrat Thin;
  color: white;
  
  @media (max-width: 48em) {
    width: 20em;
    font-size: ${(props) => props.theme.fontmd};
    font-weight: 300;
    position: relative;
    text-align: center;
  }
 
`;

const Subtitle = styled.div`
  color: #c4a239;
  font-size: 0.4em;
  text-align: right;
  font-family: 'Butler';
`;

const Instagram = styled.div`
  color:#c4a239;
  text-decoration: none;
  &:hover,
  &:focus{
      color: white;
  };
  &:active{
      color: white;
  };
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0rem 1rem;
  h2 {
  }
  img {
    width: 100%;
    height: auto;
    z-index: 5;
  }
`;
const Photos = ({ img, name }) => {
  return (
    <Item>
      <img width="400" height="600" src={img} alt={name} />
      <h2>{name}</h2>
    </Item>
  );
};

const AboutMobile = () => {
   gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);

  /* const ScrollingRef = useRef(null); */


  /* useLayoutEffect(() => {
    let element = ref.current;

    let scrollingElement = ScrollingRef.current;
    let t1= gsap.timeline();
    setTimeout(() => {
      let mainHeight = scrollingElement.scrollHeight;
      element.style.height = `calc(${mainHeight / 4}px)`;
      t1.to(element, {
        scrollTrigger: {
          trigger: element,
          start: 'top top',
          end: 'bottom+=100% top-=100%',
          scroller: '.App', //locomotive-scroll
          scrub: 1,
          pin: true,
          // markers: true,
        },
        ease: 'none',
      });

      t1.fromTo(
        scrollingElement,
        {
          y: '0',
        },
        {
          y: '-100%',
          scrollTrigger: {
            // id: `section-${index + 1}`,
            trigger: scrollingElement,
            start: 'top top',
            end: 'bottom top',
            scroller: '.App',
            scrub: 1,
            // markers: true,
          },
        },
      );

      ScrollTrigger.refresh();
    }, 1000);
    ScrollTrigger.refresh();

    return () => {
      t1.kill();
      ScrollTrigger.kill();
    };
  }, []); */

  return (
    <Section  ref={ref} id="fixed-target" className="about">
      <Title>
        ABOUT
        <Subtitle>
          DRES. <br />
          SORACE & <br />
          MENDOZA
        </Subtitle>
      </Title>

      <Container>
        <Photos img={img1}/>
        <Text >
            Somos los Dres. Adriano y Agustín, socios y colegas desde la residencia de cirugia general en el Hospital de Vicente López (Dr. Bernardo Houssay). 
            Hoy, como Cirujanos Plásticos, participamos activamente como miembros en diferentes sociedades científicas; Sociedad Argentina de Cirugía Plástica,
            Estética y Reparadora, la Sociedad de Cirugía Plástica de Buenos Aires, la Asociación Argentina de Cirugía Estética y la Asociación Argentina de Cirugía.
        </Text>
        <Photos img={img2} />
        <Text>
          <div>
            <h1>Agustín Mendoza</h1>
            Soy Agustín Mendoza, médico cirujano egresado de la UBA, especializado en cirugía plástica y formo parte del staff profesional en Sorace & Mendoza. Desarrollo mi actividad como médico de staff del servicio de cirugía plástica del Hospital Houssay y participo activamente como miembro de diferentes sociedades científicas. 
          </div>
        </Text>
        <Photos img={img3}/>
        <Text>
          <div>
            <h1>Adriano Sorace</h1>
            Soy Adriano Sorace, médico cirujano especializado en cirugía plástica, egresado de la UBA y formo parte del equipo que trabaja en Sorace & Mendoza. Actualmente me desempeño como médico de staff de cirugía plástica en la Trinidad de Palermo y en el Centro Médico Galeno Barrio Norte, a la vez que participo activamente como miembro de diferentes sociedades científicas.
          </div>
          <p style={{color:"white", fontFamily:"Montserrat Medium", paddingLeft:"46em", position:"absolute", top:"136%", width:"max-content", fontSize:"1.5em", zIndex:"5", right:"20px"}}>Seguinos en Instagram<br /><a href="https://www.instagram.com/dres.soracemendoza"> <Instagram>@dres.soracemendoza</Instagram></a></p>

        </Text>
      </Container>

    </Section>
  );
};

export default AboutMobile;