import '@fontsource/sirin-stencil';
import '@fontsource/kaushan-script';
import Gotham from '../fonts/Gotham-Thin.otf'
import MontserratMedium from '../fonts/Montserrat-Medium.ttf'
import MontserratThin from '../fonts/Montserrat-Thin.ttf'
import Butler from '../fonts/Butler_Medium.otf'

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
/* pie de fotos */
@font-face {
    font-family: 'Gotham';
    src: url(${Gotham}) format('opentype')
}
/* subtitulos */
@font-face {
    font-family: 'Montserrat Medium';
    src: url(${MontserratMedium}) format('truetype')
}
/* cuerpo de textos */
@font-face {
    font-family: 'Montserrat Thin';
    src: url(${MontserratThin}) format('truetype')
}
/* titulos */
@font-face {
    font-family: 'Butler';
    src: url(${Butler}) format('opentype')
}

${'' /* *{
    outline: 1px solid red !important;
}  */}

html.has-scroll-smooth {
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;  
}

*,*::before,*::after{
    margin: 0;
    padding: 0;
}
body{
    font-family: "Gotham Thin";
    overflow-x: hidden;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}
`;

export default GlobalStyles;
