import React from "react";
import styled from "styled-components";
//import {Icon} from '@iconify/react';
import Logo from "../assets/Svgs/logo-blanco.svg";
import { WhatsApp } from 'react-whatsapp-component' 


const Section = styled.section`
  min-height: 10vh;
  width: 100%;
  /* margin: 5rem auto; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;


  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};

  position: relative;

  @media (max-width: 48em) {
    margin-bottom: 5.5rem;
  }
`;

const Logo2 = styled.div`
  display: block;

  @media (max-width: 48em) {
    display: none;
  }
`;

/* const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;

  img {
    width: 10vw;
    height: auto;
  }

  h3 {
    font-family: "Kaushan Script";
    font-size: ${(props) => props.theme.fontxxl};

    @media (max-width: 48em) {
      font-size: ${(props) => props.theme.fontxl};
    }
  }
`; */

/* const FooterComponent = styled(motion.footer)`
  width: 80vw;

  @media (max-width: 48em) {
    width: 90vw;
  }
  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 2rem;
    margin-top: 1rem;
    padding: 0 1rem;

    @media (max-width: 48em) {
      justify-content: center;
    }
  }

  li {
    padding: 2rem;
    font-size: ${(props) => props.theme.fontmd};
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }

    h4{
      font-family: Montserrat Medium;
    }
    p{
      font-family: Montserrat Thin;
    }

    @media (max-width: 48em) {
      padding: 1rem;
      font-size: ${(props) => props.theme.fontsm};
    }
  }
`; */


const Footer = () => {

  return (
    <Section>
        <img
          width="170"
          height="170"
          src={Logo}
          alt="Wibe"
          
        />
      <Logo2>
        <WhatsApp phone="+5491133784025" text="Hola, quiero recibir mas informacion por favor." />
      </Logo2>
      {/* <FooterComponent
      >
        <ul>
          <li aria-hidden="true">
          <div className="d-flex">
              <Icon icon="material-symbols:location-on" width="30" />
              <h4>Direccioón:</h4>
          </div>
              <p>Av. del Libertador 2337, Olivos</p>
          </li>

          <li aria-hidden="true">
          <div className="d-flex">
              <Icon icon="material-symbols:mail-rounded" width="30" />
              <h4>Mail:</h4>
          </div>
              <p>info@example.com</p>
          </li>
          
          <li aria-hidden="true">
            <div className="d-flex">
              <Icon icon="material-symbols:call" width="30" />
              <h4>Telefono:</h4>
            </div>
              <p>+1 5589 55488 55s</p>
          </li>

        </ul>
      </FooterComponent> */}
    </Section>
  );
};

export default Footer;
